import work1 from '../../assets/Images/ourWork/ourWork1.png'
import work2 from '../../assets/Images/ourWork/ourWork2.png'
import work3 from '../../assets/Images/ourWork/ourWork3.png'
import { useTranslation } from 'react-i18next'
import s from './ourwork.module.css'
import { useState } from 'react'
import { Skeleton } from 'antd'


interface IProps {
    currentProject: string
    currentLoading: boolean
    implementedLoading: boolean
    implementedProject: string
    futureLoading: boolean
    futureProject: string
}

export const OurWork: React.FC<IProps> = ({
    currentProject,
    currentLoading,
    implementedLoading,
    implementedProject,
    futureLoading,
    futureProject
}) => {

    const { t } = useTranslation()
    const [moreProjects, setMoreProjects] = useState({
        current: false,
        implement: false,
        future: false
    })

    return (
        <div className={s.ourWorkContainer} id='our-projects'>
            <div className={s.ourWorkTitile}>
                <h1>{t('PROJECTS.TITLE')}</h1>
            </div>

            <div className={s.ourWorksDiv}>

                <div className={s.ourW1Container}>
                    <div className={moreProjects.current ? s.currentMore : s.current}>
                        <div>
                            <img src={work1} alt="" />
                            <h3>{t('PROJECTS.CURRENT')}</h3>
                        </div>

                        {
                            currentLoading ? <Skeleton active paragraph={{ rows: 2 }} /> :
                                <div
                                    className={moreProjects.current ? s.content : ''}
                                    dangerouslySetInnerHTML={{
                                        __html: moreProjects.current ?
                                            currentProject : currentProject.slice(0, 500) + '...'
                                    }}>
                                </div>
                        }

                        <div className={s.btnDiv}>
                            {
                                !currentLoading ? <button
                                    onClick={() => {
                                        setMoreProjects({ ...moreProjects, current: !moreProjects.current })
                                    }}
                                    className={s.button}>{moreProjects.current ? t('PROJECTS.READ-LESS') : t('PROJECTS.READ-MORE')}

                                </button> : null
                            }
                        </div>
                    </div>
                </div>
                <div className={s.ourW2Container}>

                    <div className={moreProjects.implement ? s.implementMore : s.implement}>
                        <div>
                            <img src={work2} alt="" />
                            <h3>{t('PROJECTS.IMPLEMENTED')}</h3>
                        </div>
                        {
                            implementedLoading ? <Skeleton active paragraph={{ rows: 2 }} /> :
                                <div
                                    className={moreProjects.implement ? s.content : ''}
                                    dangerouslySetInnerHTML={{
                                        __html: moreProjects.implement ?
                                            implementedProject : implementedProject.slice(0, 500) + '...'
                                    }}>
                                </div>
                        }

                        <div className={s.btnDiv}>
                            {
                                !implementedLoading ? <button
                                    onClick={() => {
                                        setMoreProjects({ ...moreProjects, implement: !moreProjects.implement })
                                    }}
                                    className={s.button}>{moreProjects.implement ? t('PROJECTS.READ-LESS') : t('PROJECTS.READ-MORE')}

                                </button> : null
                            }
                        </div>
                    </div>
                    <div className={moreProjects.future ? s.futureMore : s.future}>
                        <div>
                            <img src={work3} alt="" />
                            <h3>{t('PROJECTS.FUTURE')}</h3>
                        </div>

                        {
                            futureLoading ? <Skeleton active paragraph={{ rows: 2 }} /> :
                                <div
                                    className={moreProjects.future ? s.content : ''}
                                    dangerouslySetInnerHTML={{
                                        __html: moreProjects.future ?
                                            futureProject : futureProject.slice(0, 450) + '...'
                                    }}>
                                </div>
                        }

                        <div className={s.btnDiv}>
                            {
                                !futureLoading ? <button
                                    onClick={() => {
                                        setMoreProjects({ ...moreProjects, future: !moreProjects.future })
                                    }}
                                    className={s.button}>{moreProjects.future ? t('PROJECTS.READ-LESS') : t('PROJECTS.READ-MORE')}

                                </button> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

