import { createSlice, createAsyncThunk, AnyAction } from "@reduxjs/toolkit";
import { api } from "../services/base-url"
import { Language } from "../common/enums";
import { IState } from "./interface";

interface INews {
    newsData: object,
    newsLoading: boolean
}

const initialState: INews = {
    newsData: [],
    newsLoading: false
}


export const getNews = createAsyncThunk(
    'news/get',
    async (lng: Language, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get('news', {
                params: { lng }
            })
            return fulfillWithValue(data.data)
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)


const newsSlice = createSlice({
    name: 'news',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getNews.pending, (state: INews) => {
                state.newsLoading = true
            })
            .addCase(getNews.fulfilled, (state: INews, action: AnyAction) => {
                state.newsData = action.payload
                state.newsLoading = false
            })
    }
})

export const newsState = (state: IState) => state.newsSlice;
export default newsSlice.reducer;