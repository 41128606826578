import { createSlice, createAsyncThunk, AnyAction } from "@reduxjs/toolkit";
import { api } from "../services/base-url";
import { IState } from "./interface";

interface IMail {
    mailLoading: boolean
    mailSuccess: boolean
    mailFail: boolean | string
    verification: {
        loading: boolean,
        success: boolean,
        fail: boolean,
        conflict: boolean,
    }
}

const initialState: IMail = {
    mailLoading: false,
    mailSuccess: false,
    mailFail: false,
    verification: {
        loading: false,
        success: false,
        fail: false,
        conflict: false,
    }
}

export const sendMail = createAsyncThunk(
    'mail/send',
    async (mail: string, { rejectWithValue, fulfillWithValue }) => {
        try {
            const {data} = await api.post('mail/send', { mail })
            return fulfillWithValue(data.data)
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const verifyMail = createAsyncThunk(
    'mail/verify',
    async (token: string, { rejectWithValue, fulfillWithValue }) => {
        try {
            const {data} = await api.post('mail/verify', { token })
            return fulfillWithValue(data.data)
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)


const mailSlice = createSlice({
    name: 'mail',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder

            .addCase(sendMail.pending, (state: IMail) => {
                state.mailFail = false
                state.mailLoading = true
                state.mailSuccess = false
            })
            .addCase(sendMail.fulfilled, (state: IMail) => {
                state.mailFail = false
                state.mailLoading = false
                state.mailSuccess = true
            })
            .addCase(sendMail.rejected, (state: IMail, action: AnyAction) => {
                state.mailSuccess = false
                state.mailLoading = false
                if (action?.payload?.response?.data?.error?.EXISTS) {
                    state.mailFail = action.payload.response.data.error.EXISTS
                } else {
                    state.mailFail = true
                }
            })


            .addCase(verifyMail.pending, ({ verification }: IMail) => {
                verification.loading = true
            })
            .addCase(verifyMail.fulfilled, ({ verification }: IMail) => {
                verification.loading = false
                verification.success = true
            })
            .addCase(verifyMail.rejected, ({ verification }: IMail, action: AnyAction) => {
                if (action.payload.response.status === 409) {
                    verification.conflict = true
                } else {
                    verification.conflict = false
                    verification.fail = true
                }
                verification.loading = false
            })
    }
})



export const mailState = (state: IState) => state.mailSlice;
export default mailSlice.reducer;