import { createSlice, createAsyncThunk, AnyAction } from "@reduxjs/toolkit";
import { api } from "../services/base-url"
import { IState } from "./interface";

export interface ICourseData {
    id: number
    armenian: string
    english: string
}

interface ICourse {
    coursesLoading: boolean,
    coursesData: Array<ICourseData> | [],
}

const initialState: ICourse = {
    coursesLoading: false,
    coursesData: [],
}

export const getCourses = createAsyncThunk(
    'courses/get',
    async (_, { rejectWithValue, fulfillWithValue }) => {
        try {
            const {data} = await api.get('courses')
            return fulfillWithValue(data.data)
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

const courseSlice = createSlice({
    name: 'courses',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getCourses.pending, (state: ICourse) => {
                state.coursesData = []
                state.coursesLoading = true
            })
            .addCase(getCourses.fulfilled, (state: ICourse, action: AnyAction) => {
                state.coursesData = action.payload
                state.coursesLoading = false
            })
    }
})


export const courseState = (state: IState) => state.courseSlice;
export default courseSlice.reducer;