import { createSlice, createAsyncThunk, AnyAction } from "@reduxjs/toolkit";
import { api } from "../services/base-url"
import { IState } from "./interface";

export interface IVolunteerData {
    fullname: string,
    phone: string,
    email: string,
    socialLink: string,
    region: string,
    institute: string,
    profession: string,
    teachingExperience: string,
    aboutExperience: string,
    volunteerPeriod: string,
    professionalAdviceDesire: string,
    speciallyAdviceNeeded: string,
    weekDevoteTime: string,
    subjectsGoingToTeach: string,
    onlineToolsKnowledge: string,
    internetAccess: string,
    other: string
}

interface IVolunteer {
    volunteerLoading: boolean
    volunteerFail: boolean
    volunteerSuccess: boolean
}

const initialState = {
    volunteerLoading: false,
    volunteerFail: false,
    volunteerSuccess: false
}

export const createVolunteer = createAsyncThunk(
    'volunteer/create',
    async (data: IVolunteerData, { rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await api.post('volunteer', data)
            return fulfillWithValue(response.data.data)
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

const volunteerSlice = createSlice({
    name: 'volunteer',
    initialState,
    reducers: {
        defaultState(state: IVolunteer) {
            state.volunteerFail = false
            state.volunteerLoading = false
            state.volunteerSuccess = false
        }
    },
    extraReducers(builder) {
        builder
            .addCase(createVolunteer.pending, (state: IVolunteer) => {
                state.volunteerFail = false
                state.volunteerSuccess = false
                state.volunteerLoading = true
            })
            .addCase(createVolunteer.fulfilled, (state: IVolunteer) => {
                state.volunteerFail = false
                state.volunteerSuccess = true
                state.volunteerLoading = false
            })
            .addCase(createVolunteer.rejected, (state: IVolunteer) => {
                state.volunteerFail = true
                state.volunteerSuccess = false
                state.volunteerLoading = false
            })
    }
})

export const { defaultState } = volunteerSlice.actions;
export const volunteerState = (state: IState) => state.volunteerSlice;
export default volunteerSlice.reducer;