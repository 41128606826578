import s from '../about-us/about.module.css'
import { Carousel } from 'antd'
import './story.css'
// @ts-ignore
import ImageContentHover from 'react-image-hover';
import { SUCCESS_URL } from '../../common/constants'
import { useTranslation } from 'react-i18next'

export const SuccessStory: React.FC<any> = ({
    storyList
}) => {
    const {t} = useTranslation()

return <>
    <h2 className={s.successTitle}>{t('HEADER.SUCCESS-STORY')}</h2>
    <div className={s.aboutThirdBox} id='success-story'>
        <div className={s.successBox}>
            <Carousel autoplay className={s.carousel} >

                {
                    storyList?.map((elem:any) => <div className={s.contentBox}>
                        <div className={s.successImgBox}>
                            <ImageContentHover
                                className={s.imgHover}
                                image={SUCCESS_URL+elem.imgPath}
                                content={{
                                    title: elem.fullname,
                                }}
                                effect="fadeIn" />

                        </div>
                        <div className={s.successTextBox}>{elem.text}</div>
                    </div>)
                }

            </Carousel>
        </div>
    </div>
    </>
}


