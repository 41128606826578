import i18n from "i18next";


export let regions: any;
export let answerWithPartly: any;
export let answer: any;

i18n.init().then(() => {
    regions = [
        { value: 'Lori', label: i18n.t('OPTIONS.REGIONS.LORI') },
        { value: 'Shirak', label: i18n.t('OPTIONS.REGIONS.SHIRAK') },
        { value: 'Tavush', label: i18n.t('OPTIONS.REGIONS.TAVUSH') },
        { value: 'Aragatsotn', label: i18n.t('OPTIONS.REGIONS.ARAGATSOTN') },
        { value: 'Kotayk', label: i18n.t('OPTIONS.REGIONS.KOTAYK') },
        { value: 'Gegharkunik', label: i18n.t('OPTIONS.REGIONS.GEGHARKUNIK') },
        { value: 'Armavir', label: i18n.t('OPTIONS.REGIONS.ARMAVIR') },
        { value: 'Ararat', label: i18n.t('OPTIONS.REGIONS.ARARAT') },
        { value: 'Vayots dzor', label: i18n.t('OPTIONS.REGIONS.VAYOTS-DZOR') },
        { value: 'Syunik', label: i18n.t('OPTIONS.REGIONS.SYUNIK') },
        { value: 'Yerevan', label: i18n.t('OPTIONS.REGIONS.YEREVAN') },
        { value: 'Republic of Artsakh', label: i18n.t('OPTIONS.REGIONS.ARTSAKH') }
    ];
    answerWithPartly = [
        { value: 'Yes', label: i18n.t('OPTIONS.COMMON.YES') },
        { value: 'No', label: i18n.t('OPTIONS.COMMON.NO') },
        { value: 'Partly', label: i18n.t('OPTIONS.COMMON.PARTLY') },
    ];
    answer = [
        { value: 'Yes', label: i18n.t('OPTIONS.COMMON.YES-NEXT') },
        { value: 'No', label: i18n.t('OPTIONS.COMMON.NO') },
    ]
})



