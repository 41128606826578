import { createSlice, createAsyncThunk, AnyAction } from "@reduxjs/toolkit";
import { api } from "../services/base-url";
import { IState } from "./interface";

interface IImpact {
    impactData: object,
    impactLoading: boolean
}


const initialState: IImpact = {
    impactData: {},
    impactLoading: false
}



export const getImpacts = createAsyncThunk(
    'impact/get',
    async (_, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get('impact')
            return fulfillWithValue(data.data)
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)


const impactSlice = createSlice({
    name: 'impact',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getImpacts.pending, (state: IImpact) => {
                state.impactData = {};
                state.impactLoading = true;
            })
            .addCase(getImpacts.fulfilled, (state: IImpact, action: AnyAction) => {
                state.impactData = typeof action.payload.data === 'string' ?
                    JSON.parse(action.payload.data) : action.payload.data;
                state.impactLoading = false;
            })
    }
})

export const impactState = (state: IState) => state.impactSlice;
export default impactSlice.reducer;