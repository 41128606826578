import { useTranslation } from 'react-i18next'
import map from '../../assets/Images/map.png'
import { MarqueeImpact } from './marquee'
import s from './ourimpact.module.css'

interface IProps {
    impactLoading: boolean,
    impactData: any
}

export const OurImpact: React.FC<IProps> = ({impactLoading, impactData}) => {
    
    const {t} = useTranslation()
    return (
        <div className={s.ourImpactDiv} id='our-impact'>

            <div className={s.ourImpactContainer}>

                <div className={s.title}>
                    {t('IMPACT.TITLE')}
                </div>
                <div className={s.statusBox}>
                    <MarqueeImpact impactData={impactData} impactLoading={impactLoading} />
                </div>
                <div className={s.map}>
                    <img className={s.img} src={map} alt="" />
                </div>
            </div>
        </div>
    )
}