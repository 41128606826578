import { createSlice, createAsyncThunk, AnyAction } from "@reduxjs/toolkit";
import { api } from "../services/base-url";
import { Language } from "../common/enums";
import { Projects } from "../common/enums/project.enum";
import { IState } from "./interface";


interface IProjectsState {
    currentLoading: boolean,
    futureLoading: boolean,
    implementedLoading: boolean,
    currentProjects: '',
    implementedProjects: '',
    futureProjects: ''
}


const initialState: IProjectsState = {
    currentLoading: false,
    futureLoading: false,
    implementedLoading: false,
    currentProjects: '',
    implementedProjects: '',
    futureProjects: ''
}



export const getCurrentProject = createAsyncThunk(
    'project/current/get',
    async (lng: Language, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get('projects', {
                params: {
                    projectType: Projects.CURRENT,
                    lng
                }
            })
            return fulfillWithValue(data.data)
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const getImplementedProject = createAsyncThunk(
    'project/implemented/get',
    async (lng: Language, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get('projects', {
                params: {
                    projectType: Projects.IMPLEMENTED,
                    lng
                }
            })
            return fulfillWithValue(data.data)
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const getFutureProject = createAsyncThunk(
    'project/future/get',
    async (lng: Language, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get('projects', {
                params: {
                    projectType: Projects.FUTURE,
                    lng
                }
            })
            return fulfillWithValue(data.data)
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)


const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getFutureProject.pending, (state: IProjectsState) => {
                state.futureLoading = true
            })
            .addCase(getCurrentProject.pending, (state: IProjectsState) => {
                state.currentLoading = true
            })
            .addCase(getImplementedProject.pending, (state: IProjectsState) => {
                state.implementedLoading = true
            })

            .addCase(getCurrentProject.fulfilled, (state: IProjectsState, action: AnyAction) => {
                state.currentProjects = action.payload.content || ''
                state.currentLoading = false
            })

            .addCase(getFutureProject.fulfilled, (state: IProjectsState, action: AnyAction) => {
                state.futureProjects = action.payload.content || ''
                state.futureLoading = false
            })

            .addCase(getImplementedProject.fulfilled, (state: IProjectsState, action: AnyAction) => {
                state.implementedProjects = action.payload.content || ''
                state.implementedLoading = false
            })
    }
})

export const projectsState = (state: IState) => state.projectSlice;
export default projectSlice.reducer;