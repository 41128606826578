import { Alert } from 'antd'
import s from './input.module.css'
import FadeIn from 'react-fade-in/lib/FadeIn'

export const Input: React.FC<any> = ({label, name, id, onChange, value, errMess, placeholder,  disabled}) => {


    return <div className={s.group}>
        <label>{label} </label>
        {errMess ? <p className={s.errMess}><FadeIn>{errMess}</FadeIn> </p>: null}
        <input 
        disabled={disabled}
        placeholder={placeholder}
        name={name} value={value} onChange={onChange} id={id}
        type="text" /><span className={s.highlight}></span><span className={s.bar}></span> 
    </div>

}

