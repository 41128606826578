import * as yup from 'yup'
import i18n from 'i18next'
//@ts-ignore
import libphonenumber from 'google-libphonenumber'

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

export let volunteersSchema: any;
export let studentsSchema: any;

i18n.init().then(() => {

    const required = i18n.t('VALIDATION.ERRORS.REQUIRED')
    const invalid = i18n.t('VALIDATION.ERRORS.INVALID')
    const choose = i18n.t('VALIDATION.ERRORS.CHOOSE')
    const phone = i18n.t('VALIDATION.ERRORS.PHONE')
    const email = i18n.t('VALIDATION.ERRORS.EMAIL')
    const link = i18n.t('VALIDATION.ERRORS.LINK')
    const min = i18n.t('VALIDATION.ERRORS.MIN')


    volunteersSchema = yup.object().shape({
        fullname: yup.string().required(required).min(6, min),
        phone: yup.string().required(required).test('phone', phone, (val) => {
            if (!val) return true
            try {
                const phoneNumber = phoneUtil.parseAndKeepRawInput(val, 'AM');
                return phoneUtil.isValidNumber(phoneNumber);
            } catch (e) {
                return false;
            }
        }).matches(/^(?!.*\s{2,})/, invalid),
        email: yup.string().required(required).matches(/^([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})$/, email),
        socialLink: yup.string().required(required).url(link),
        region: yup.string().required(choose),
        institute: yup.string().required(required),
        profession: yup.string().required(required),
        teachingExperience: yup.string().required(choose),
        aboutExperience: yup.string(),
        volunteerPeriod: yup.string().required(required),
        professionalAdviceDesire: yup.string().required(choose),
        speciallyAdviceNeeded: yup.string(),
        weekDevoteTime: yup.string().required(required),
        subjectsGoingToTeach: yup.string().required(required),
        onlineToolsKnowledge: yup.string().required(choose),
        internetAccess: yup.string().required(choose),
        other: yup.string()

    })

    studentsSchema = yup.object().shape({
        fullname: yup.string().required(required).min(6, min).matches(/^\s*[^\s].*$/, required),
        phone: yup.string().required(required).test('phone', phone, (val) => {
            if (!val) return true
            try {
                const phoneNumber = phoneUtil.parseAndKeepRawInput(val, 'AM');
                return phoneUtil.isValidNumber(phoneNumber);
            } catch (e) {
                return false;
            }
        }).matches(/^(?!.*\s{2,})/, invalid),
        email: yup.string().required(required).matches(/^([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})$/, email),
        telegram: yup.string().required(required).url(link),
        facebook: yup.string().url(link),
        region: yup.string().required(required),
        classroom: yup.string().required(required),
        courseId: yup.string().required(choose),
        other: yup.string()
    })
})
