import { Button, Select } from 'antd'
import { Input } from '../input'
import s from './teachers.module.css'
import { useFormik } from 'formik'

import { createVolunteer, defaultState, volunteerState, IVolunteerData } from '../../slices/volunteer.slice'
import { volunteersSchema } from '../../common/validation/schemes'
import { answer, answerWithPartly, regions } from './options'
import { useTranslation } from 'react-i18next'
import FadeIn from 'react-fade-in/lib/FadeIn'
import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../hook'
import { useSelector } from 'react-redux'
import { Alert, message } from 'antd'



export const VolunteersForm: React.FC<any> = ({ onClose }) => {

    const { t } = useTranslation();
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useAppDispatch()
    const { volunteerLoading, volunteerSuccess, volunteerFail } = useSelector(volunteerState)
    const [showAlert, setShowAlert] = useState(false)

    const success = () => {
        messageApi.open({
            type: 'success',
            content: t('MESSAGES.SUCCESS'),
            duration: 4,
            onClose: () => {
                onClose()
                const lng = localStorage.getItem('i18nextLng')
                localStorage.clear()
                if (lng) {
                    localStorage.setItem('i18nextLng', lng)
                }
                form.resetForm({})
            }
        });
    };

    const error = () => {
        messageApi.open({
            type: 'error',
            content: t('MESSAGES.FAILED'),
            duration: 4,
            onClose: () => onClose()

        });
    };

    const form = useFormik({
        initialValues: {
            fullname: localStorage.getItem('fullname-V') || '',
            phone: localStorage.getItem('phone-V') || '',
            email: localStorage.getItem('email-V') || '',
            socialLink: localStorage.getItem('socialLink-V') || '',
            region: localStorage.getItem('region-V') || '',
            institute: localStorage.getItem('institute-V') || '',
            profession: localStorage.getItem('profession-V') || '',
            teachingExperience: localStorage.getItem('teachingExperience-V') || '',
            aboutExperience: localStorage.getItem('aboutExperience-V') || '',
            volunteerPeriod: localStorage.getItem('volunteerPeriod-V') || '',
            professionalAdviceDesire: localStorage.getItem('professionalAdviceDesire-V') || '',
            speciallyAdviceNeeded: localStorage.getItem('speciallyAdviceNeeded-V') || '',
            weekDevoteTime: localStorage.getItem('weekDevoteTime-V') || '',
            subjectsGoingToTeach: localStorage.getItem('subjectsGoingToTeach-V') || '',
            onlineToolsKnowledge: localStorage.getItem('onlineToolsKnowledge-V') || '',
            internetAccess: localStorage.getItem('internetAccess-V') || '',
            other: localStorage.getItem('other-V') || ''
        },
        validationSchema: volunteersSchema,
        onSubmit: v => {
            dispatch(createVolunteer(v))
        }
    })

    useEffect(() => {
        if (volunteerFail) {
            error()
            dispatch(defaultState())
        }
        if (volunteerSuccess) {
            success()
            dispatch(defaultState())
        }
    }, [volunteerFail, volunteerSuccess])


    useEffect(() => {
        for (const key in form.values) {
            let value = form.values[key as keyof IVolunteerData]
            localStorage.setItem(key + '-V', value)
        }
    }, [form.values])


    return <form className={s.form} onSubmit={form.handleSubmit}>
        {contextHolder}
        <div className={s.formInpBox}>
            <div className={s.formLeft}>
                <Input
                    errMess={(form.errors.fullname && form.touched.fullname) ? form.errors.fullname : null}
                    id='fullname' name='fullname' value={form.values.fullname?.trimStart()}
                    onChange={form.handleChange} label={t('VOLUNTEER.FULLNAME')} />
                <Input
                    errMess={(form.errors.phone && form.touched.phone) ? form.errors.phone : null}
                    id='phone' name='phone' value={form.values.phone}
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                        form.setFieldValue('phone', ev.target.value.startsWith('+') ? ev.target.value : `+${ev.target.value}`)
                    }} label={t('VOLUNTEER.PHONE')} />
                <Input
                    errMess={(form.errors.email && form.touched.email) ? form.errors.email : null}
                    id='email' name='email' value={form.values.email?.trimStart()}
                    onChange={form.handleChange} label={t('VOLUNTEER.EMAIL')} />
                <Input
                    errMess={(form.errors.socialLink && form.touched.socialLink) ? form.errors.socialLink : null}
                    id='socialLink' name='socialLink' value={form.values.socialLink}
                    onChange={form.handleChange} label={t('VOLUNTEER.SOCIAL-LINK')} />

                <>
                    <span className={s.label}>
                        {t('VOLUNTEER.REGION')}
                    </span>
                    {(form.errors.region && form.touched.region) ?
                        <p className={s.errMess}><FadeIn>{form.errors.region}</FadeIn></p> : null}
                    <Select
                        className={s.select}
                        onSelect={(v) => form.setFieldValue('region', v)}
                        defaultValue={form.values.region || t('FORM.COMMON.CHOOSE')}
                        options={regions}
                    />
                </>
                <Input
                    errMess={(form.errors.institute && form.touched.institute) ? form.errors.institute : null}
                    id='institute' name='institute' value={form.values.institute?.trimStart()}
                    onChange={form.handleChange} label={t('VOLUNTEER.INSTITUT')} />
                <Input
                    errMess={(form.errors.profession && form.touched.profession) ? form.errors.profession : null}
                    id='profession' name='profession' value={form.values.profession?.trimStart()}
                    onChange={form.handleChange} label={t('VOLUNTEER.PROFESSION')} />

                <>
                    <span className={s.label}>
                        {t('VOLUNTEER.TEACHEING-EXPERIENCE')}
                    </span>
                    {(form.errors.teachingExperience && form.touched.teachingExperience) ?
                        <p className={s.errMess}><FadeIn>{form.errors.teachingExperience}</FadeIn></p> : null}
                    <Select
                        className={s.select}
                        onSelect={(v) => form.setFieldValue('teachingExperience', v)}
                        defaultValue={form.values.teachingExperience || t('FORM.COMMON.CHOOSE')}
                        options={answer}
                    />
                </>

                <Input
                    errMess={(form.errors.aboutExperience && form.touched.aboutExperience) ? form.errors.aboutExperience : null}
                    id='aboutExperience' name='aboutExperience' value={form.values.aboutExperience?.trimStart()}
                    onChange={form.handleChange} label={t('VOLUNTEER.ABOUT-EXPERIENCE')} />


            </div>
            <div className={s.formRight}>

                <Input
                    errMess={(form.errors.volunteerPeriod && form.touched.volunteerPeriod) ? form.errors.volunteerPeriod : null}
                    id='volunteerPeriod' name='volunteerPeriod' value={form.values.volunteerPeriod?.trimStart()}
                    onChange={form.handleChange} label={t('VOLUNTEER.VOLUNTEER-PERIOD')} />
                <>
                    <span className={s.label}>
                        {t('VOLUNTEER.PROFESSIONAL-ADVICE-DESIRE')}
                    </span>
                    {(form.errors.professionalAdviceDesire && form.touched.professionalAdviceDesire) ?
                        <p className={s.errMess}>{form.errors.professionalAdviceDesire}</p> : null}
                    <Select
                        className={s.select}
                        onSelect={(v) => form.setFieldValue('professionalAdviceDesire', v)}
                        defaultValue={form.values.professionalAdviceDesire || t('FORM.COMMON.CHOOSE')}
                        options={answer}
                    />
                </>
                <Input
                    errMess={(form.errors.speciallyAdviceNeeded && form.touched.speciallyAdviceNeeded) ? form.errors.speciallyAdviceNeeded : null}
                    id='speciallyAdviceNeeded' name='speciallyAdviceNeeded' value={form.values.speciallyAdviceNeeded?.trimStart()}
                    onChange={form.handleChange} label={t('VOLUNTEER.SPECIALLY-ADVICE-NEEDED')} />
                <Input
                    errMess={(form.errors.weekDevoteTime && form.touched.weekDevoteTime) ? form.errors.weekDevoteTime : null}
                    id='weekDevoteTime' name='weekDevoteTime' value={form.values.weekDevoteTime?.trimStart()}
                    onChange={form.handleChange} label={t('VOLUNTEER.WEEK-DEVOTE-TIME')} />
                <Input
                    errMess={(form.errors.subjectsGoingToTeach && form.touched.subjectsGoingToTeach) ? form.errors.subjectsGoingToTeach : null}
                    id='subjectsGoingToTeach' name='subjectsGoingToTeach' value={form.values.subjectsGoingToTeach?.trimStart()}
                    onChange={form.handleChange} label={t('VOLUNTEER.SUBJECT-GOING-TO-TEACH')} />
                <>
                    <span className={s.label}>
                        {t('VOLUNTEER.ONLINE-TOOLS-KNOWLEDGE')}
                    </span>
                    {(form.errors.onlineToolsKnowledge && form.touched.onlineToolsKnowledge) ?
                        <p className={s.errMess}><FadeIn>{form.errors.onlineToolsKnowledge}</FadeIn></p> : null}
                    <Select
                        className={s.select}

                        onSelect={(v) => form.setFieldValue('onlineToolsKnowledge', v)}
                        defaultValue={form.values.onlineToolsKnowledge || t('FORM.COMMON.CHOOSE')}
                        options={answerWithPartly}
                    />
                </>
                <>
                    <span className={s.label}>
                        {t('VOLUNTEER.INTERNET-ACCESS')}
                    </span>
                    {(form.errors.internetAccess && form.touched.internetAccess) ?
                        <p className={s.errMess}><FadeIn>{form.errors.internetAccess}</FadeIn></p> : null}
                    <Select
                        className={s.select}
                        onSelect={(v) => form.setFieldValue('internetAccess', v)}
                        defaultValue={form.values.internetAccess || t('FORM.COMMON.CHOOSE')}
                        options={answerWithPartly}
                    />
                </>
                <Input
                    errMess={(form.errors.other && form.touched.other) ? form.errors.other : null}
                    id='other' name='other' value={form.values.other?.trimStart()}
                    onChange={form.handleChange} label={t('FORM.COMMON.OTHER')} />
            </div>
        </div>
        <div className={s.formSubmit}>
            {
                (Object.keys(form.errors).length && showAlert) ?
                    <Alert className={s.errMessAlert}
                        type='error' message={t('VALIDATION.ERRORS.FAILED')} /> : null
            }
            <Button
                type='primary' htmlType='submit'
                className={s.joinBtn}
                loading={volunteerLoading}
                onClick={() => setShowAlert(true)}
            >{t('FORM.COMMON.JOIN')}</Button>
        </div>
    </form>
}