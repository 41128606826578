import { Navigate, Route, Routes } from "react-router-dom"
import { Main, Verification } from "../pages"



export const MainRoute: React.FC = () => {
     
    return <Routes>
        <Route path="/" element={<Main/>}/>
        <Route path="/email-verification" element={<Verification/>} />
        <Route path="*" element={<Navigate to="/" />}/>
    </Routes>
}