import './App.css';
import {RootComponent} from './components';
import './i18';


const App: React.FC = () => {
  return (
    <div className="App">
     <RootComponent/>
    </div>
  );
}

export default App;
