import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import hy from './locales/hy.json'
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
    'en': {
        translation: en,
    },
    'hy': {
        translation: hy
    }
}

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        fallbackLng: 'en',
    });