import { createSlice, createAsyncThunk, AnyAction } from "@reduxjs/toolkit";
import { api } from "../services/base-url"
import { IState } from "./interface";

export interface IStudentData {
    fullname: string
    phone: string
    email: string
    telegram: string
    facebook: string
    region: string
    classroom: string
    courseId: string
    other: string
}

interface IStudent {
    studentLoading: boolean,
    studentFail: boolean,
    studentSuccess: boolean

}

const initialState = {
    studentLoading: false,
    studentFail: false,
    studentSuccess: false
}

export const createStudent = createAsyncThunk(
    'student/create',
    async (data: IStudentData, {rejectWithValue, fulfillWithValue}) => {
        try {
            const response= await api.post('students', data)
            return fulfillWithValue(response.data.data)
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)


const studentSlice = createSlice({
    name: 'student',
    initialState,
    reducers: {
        defaultState(state: IStudent) {
            state.studentFail = false
            state.studentLoading = false
            state.studentSuccess = false
        }
    },
    extraReducers(builder) {
        builder
            .addCase(createStudent.pending, (state: IStudent) => {
                state.studentFail = false
                state.studentSuccess = false
                state.studentLoading = true
            })
            .addCase(createStudent.fulfilled, (state: IStudent) => {
                state.studentFail = false
                state.studentSuccess = true
                state.studentLoading = false
            })
            .addCase(createStudent.rejected, (state: IStudent) => {
                state.studentFail = true
                state.studentSuccess = false
                state.studentLoading = false
            })
    }
})

export const { defaultState } = studentSlice.actions;
export const studentState = (state: IState) => state.studentSlice;
export default studentSlice.reducer;