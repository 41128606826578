import { Skeleton } from 'antd'
import s from './projects.module.css'

export const CurrentProjects: React.FC<any> = ({ data, loading }) => {
    if (loading) return <Skeleton active paragraph={{rows:15}} />
    return <div className={s.contentBox} dangerouslySetInnerHTML={{__html:data}} ></div>
}


export const ImplementedProjects: React.FC<any> = ({ data, loading }) => {
    if (loading) return <Skeleton active paragraph={{rows:15}} />
    return <div className={s.contentBox} dangerouslySetInnerHTML={{__html:data}} ></div>
}

export const FutureProjects: React.FC<any> = ({ data, loading }) => {
    if (loading) return <Skeleton active paragraph={{rows:15}} />
    return <div className={s.contentBox} dangerouslySetInnerHTML={{__html:data}} ></div>
}