// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".main_container__h1QUf {\n    margin: auto;\n    width: 80%;\n    max-width: 1600px;\n}\n\n.main_drawerContainer__MPeVi{\n    width: 100%;\n    height: auto;\n    display: flex;\n}\n\n.main_drawer__MmJim{\n    width: 100%;\n}\n\n.main_drawerTitle__quK66{\n    color: #797979;\n    font-size: 15px;\n    margin: 0;\n}", "",{"version":3,"sources":["webpack://./src/pages/main/main.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,UAAU;IACV,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,cAAc;IACd,eAAe;IACf,SAAS;AACb","sourcesContent":[".container {\n    margin: auto;\n    width: 80%;\n    max-width: 1600px;\n}\n\n.drawerContainer{\n    width: 100%;\n    height: auto;\n    display: flex;\n}\n\n.drawer{\n    width: 100%;\n}\n\n.drawerTitle{\n    color: #797979;\n    font-size: 15px;\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "main_container__h1QUf",
	"drawerContainer": "main_drawerContainer__MPeVi",
	"drawer": "main_drawer__MmJim",
	"drawerTitle": "main_drawerTitle__quK66"
};
export default ___CSS_LOADER_EXPORT___;
