import { configureStore } from "@reduxjs/toolkit";
import volunteerSlice from './slices/volunteer.slice'
import courseSlice from "./slices/course.slice"
import studentSlice from "./slices/student.slice";
import newsSlice from "./slices/news.slice";
import aboutSlice from "./slices/about.slice";
import projectSlice from "./slices/project.slice";
import impactSlice from "./slices/impact.slice";
import contactSlice from "./slices/contact.slice";
import successStorySlice from "./slices/success-story.slice";
import mailSlice from "./slices/mail.slice";

const store = configureStore({
    reducer: {
        volunteerSlice,
        courseSlice,
        studentSlice,
        newsSlice,
        aboutSlice,
        projectSlice,
        impactSlice,
        contactSlice,
        successStorySlice,
        mailSlice
    }
})


export type AppDispatch = typeof store.dispatch;

export default store;