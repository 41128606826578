import { createSlice, createAsyncThunk, AnyAction } from "@reduxjs/toolkit";
import { api } from "../services/base-url";
import { Language } from "../common/enums";
import { IState } from "./interface";

interface IStory {
    storyLoading: boolean,
    storyList: object
}


const initialState: IStory = {
    storyLoading: false,
    storyList: []
}


export const getStories = createAsyncThunk(
    'story/get',
    async (lng: Language, { rejectWithValue, fulfillWithValue }) => {
        try {
            const {data} = await api.get('success-story', {
                params: {
                    lng
                }
            })
            return fulfillWithValue(data.data)
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)


const storySlice = createSlice({
    name: 'story',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getStories.pending, (state:IStory) => {
                state.storyLoading = true
            })

            .addCase(getStories.fulfilled, (state:IStory, action:AnyAction) => {
                state.storyLoading = false
                state.storyList = action.payload
            })
    }
})

export const storyState = (state: IState) => state.successStorySlice

export default storySlice.reducer;