import { mailState, sendMail } from '../../slices/mail.slice'
import instagram from '../../assets/Images/instagram.svg'
import facebook from '../../assets/Images/facebook.svg'
import { Button, Skeleton, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../hook'
import { useSelector } from 'react-redux'
import s from './footer.module.css'
import { useFormik } from 'formik'
import { useEffect } from 'react'
import { Input } from '../input'
import * as yup from 'yup'

interface IProps {
    contactData: {
        email: string
        firstPhone: string
        secondPhone: string
        address: string
        instagram: string
        facebook: string
    }
    contactLoading: boolean
}

export const Footer: React.FC<IProps> = ({ contactData, contactLoading }) => {

    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const [api, contextHolder] = notification.useNotification();
    const { mailLoading, mailFail, mailSuccess } = useSelector(mailState)

    const openNotification = (type: 'info' | 'error', message: string) => {
        api[type]({
            message,
            placement: 'topRight',
            duration: 10
        });
    };
    const form = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: yup.object().shape({
            email: yup.string()
                .matches(/^([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})$/,
                    t('VALIDATION.ERRORS.EMAIL').toString())
        }),
        onSubmit: v => {
            dispatch(sendMail(v.email))
        }
    })


    useEffect(() => {
        if (mailSuccess) {
            openNotification('info', t("CONTACT.SUCCESS"),)
            form.resetForm({})
        }
        if (mailFail) {
            if (typeof mailFail === 'string') {
                openNotification('error', t("CONTACT.EMAIL_EXISTS"))
            } else {
                openNotification('error', t("CONTACT.FAIL"))
            }

        }
    }, [mailFail, mailSuccess])

    return (
        <div className={s.footer} id='contact'>
            {contextHolder}
            <div className={s.contact}>
                <div className={s.formBox}>
                    <form onSubmit={form.handleSubmit} className={s.form}>
                        <div className={s.inpDiv}>
                            <Input
                                disabled={mailLoading}
                                errMess={form.errors.email && form.touched.email ? form.errors.email : null}
                                onChange={form.handleChange}
                                value={form.values.email}
                                id='email' name='email'
                                placeholder='example@gmail.com'
                                label={t('CONTACT.SIGN-UP-LABEL')} />
                        </div>
                        <div className={s.btnDiv}>
                            <Button
                                loading={mailLoading}
                                disabled={mailLoading || !form.values.email.length}
                                htmlType='submit'
                                className={s.signUpBtn}>{t('CONTACT.SIGN-UP')}</Button>
                        </div>
                    </form>
                </div>
                <div className={s.contactInfoBox}>

                    {
                        contactLoading ? <Skeleton style={{ width: window.innerWidth > 680 ? '50%' : '100%' }} active paragraph={{ rows: 5 }} />
                            :
                            <div className={s.contactInfo}>
                                <h2>{t('CONTACT.CONTACT')}</h2>
                                <div className={s.contactBox}>
                                    <div className={s.contactPart}>
                                        <p className={s.contactName}>{t('CONTACT.EMAIL')}</p>
                                        <u className={s.contactData}>{contactData.email}</u>
                                    </div>
                                    <div className={s.contactPart}>
                                        <p className={s.contactName}>{t('CONTACT.PHONE')}</p>
                                        <p className={s.contactData}>{contactData.firstPhone}</p>
                                        <p className={s.contactData}>{contactData.secondPhone}</p>
                                    </div>
                                    <div className={s.contactPart}>
                                        <p className={s.contactName}>{t('CONTACT.ADDRESS')}</p>
                                        <p className={s.contactData}>{contactData.address}</p>
                                    </div>
                                </div>
                                <div className={s.linksBox}>
                                    <div className={s.linkBox}>
                                        <img
                                            onClick={() => {
                                                window.open(contactData.instagram, '_blank')
                                            }}
                                            className={s.link} src={instagram} alt="" />
                                    </div>
                                    <div className={s.linkBox}>
                                        <img
                                            onClick={() => {
                                                window.open(contactData.facebook, '_blank')
                                            }}
                                            className={s.link} src={facebook} alt="" />
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>
            <div className={s.copyright}>
                <div>Matemik © {new Date().getFullYear()} All rights reserved</div>
                <div className={s.line}>&nbsp; | &nbsp;</div>
                <div className={s.createdBy}>Created By <u className={s.padc} onClick={() => window.open('https://padcllc.com/')}> PADC LLC</u></div>
            </div>
        </div>
    )
}