import { createSlice, createAsyncThunk, AnyAction } from "@reduxjs/toolkit";
import { api } from "../services/base-url";
import { IState } from "./interface";

interface IContact {
    contactData: object
    contactLoading: boolean
}

const initialState: IContact = {
    contactData: {},
    contactLoading: false
}


export const getContacts = createAsyncThunk(
    'contact/get',
    async (_, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get('contact')
            return fulfillWithValue(data.data)
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)


const contactSlice = createSlice({
    name: 'contact',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getContacts.pending, (state: IContact) => {
                state.contactData = {}
                state.contactLoading = true
            })
            .addCase(getContacts.fulfilled, (state: IContact, action: AnyAction) => {
                state.contactData = typeof action.payload.data === 'string' ?
                    JSON.parse(action.payload.data) : action.payload.data;
                state.contactLoading = false
            })
    }
})

export const contactState = (state: IState) => state.contactSlice
export default contactSlice.reducer;