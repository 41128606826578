import { mailState, verifyMail } from "../../slices/mail.slice";
import { useLocation, useNavigate } from "react-router-dom"
import { isUUID } from "../../common/helpers/is-uuid";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../hook";
import { useSelector } from "react-redux";
import { Button, Result } from "antd";
import { useEffect } from "react";


export const Verification: React.FC = () => {

    const location = useLocation()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { verification } = useSelector(mailState)
    const token = new URLSearchParams(location.search).get('token');


    useEffect(() => {
        if (!token || !isUUID(token)) {
            navigate('/')
        }
        if (token) {
            dispatch(verifyMail(token))
        }
    }, [])

    useEffect(() => {
        if (verification.conflict) {
            navigate('/')
        }
    }, [verification])
    

    if (verification.loading) return <div className="box"><div className="loader"></div></div>

    if (verification.fail) return <Result
        status={'error'}
        title={t("EMAIL_VERIFICATION.FAIL")}
        extra={[
            <Button type="primary"  onClick={() => navigate('/')}>
                {t("EMAIL_VERIFICATION.GO_HOME")}
            </Button>,
            <Button onClick={() => {
                if(token) {
                    dispatch(verifyMail(token))
                }
            }}>
                {t("EMAIL_VERIFICATION.TRY_AGAIN")}
            </Button>
        ]}
    />

    if (verification.success) return <Result
        status={'success'}
        title={t("EMAIL_VERIFICATION.SUCCESS")}
        extra={[
            <Button type="primary" onClick={() => navigate('/')} >
                {t("EMAIL_VERIFICATION.GO_HOME")}
            </Button>
        ]}
    />

    return <></>
}