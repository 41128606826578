import { RegisterType, Projects } from '../../common/enums';
import "/node_modules/flag-icons/css/flag-icons.min.css";
import langicon from '../../assets/Images/lang.svg'
import { HashLink } from 'react-router-hash-link'
import { MenuOutlined } from '@ant-design/icons'
import logo from '../../assets/Images/logo.svg'
import { useTranslation } from 'react-i18next';
import s from './header.module.css'
import { Dropdown } from 'antd';

interface IProps {
    onOpen: (args: any) => void
    showNews: boolean
    showStudents: boolean
    showStory: boolean
}

export const Header: React.FC<IProps> = ({ onOpen, showNews, showStudents, showStory }) => {

    const { t, i18n } = useTranslation();
    const lang = i18n.language;


    const mobileMenu = [
        {
            key: '1',
            type: 'group',
            label: t("HEADER.JOIN-US"),
            children: [
                {
                    key: '1-1',
                    label: (
                        <span className={s.dropSpan} onClick={() => {
                            onOpen(RegisterType.VOLUNTEER)
                        }}>
                            {t('HEADER.VOLUNTEER')}
                        </span>
                    ),
                },
                showStudents ? {
                    key: '1-2',
                    label: (
                        <span className={s.dropSpan} onClick={() => {
                            onOpen(RegisterType.STUDENT)
                        }}>
                            {t('HEADER.STUDENT')}
                        </span>
                    ),
                } : null
            ],
        },


        {
            key: '2',
            type: 'group',
            label: t("HEADER.OUR-PROJECTS"),
            children: [
                {
                    key: '2-1',
                    label: (
                        <span className={s.dropSpan} onClick={() => {
                            onOpen(Projects.CURRENT)
                        }}>
                            {t('PROJECTS.CURRENT')}
                        </span>
                    ),
                },
                {
                    key: '2-2',
                    label: (
                        <span className={s.dropSpan} onClick={() => {
                            onOpen(Projects.IMPLEMENTED)
                        }}>
                            {t('PROJECTS.IMPLEMENTED')}
                        </span>
                    ),
                },
                {
                    key: '3-2',
                    label: (
                        <span className={s.dropSpan} onClick={() => {
                            onOpen(Projects.FUTURE)
                        }}>
                            {t('PROJECTS.FUTURE')}
                        </span>
                    ),
                }
            ],
        },



        {
            key: '3',
            label: (
                <HashLink
                    className={s.dropSpan}
                    scroll={(el) => el.scrollIntoView({ block: 'start', behavior: 'smooth' })}
                    smooth to='#contact'>{t("HEADER.CONTACT-US")}</HashLink>
            ),
        },

        showNews ? {
            key: '4',
            label: (
                <HashLink
                    className={s.dropSpan}
                    scroll={(el) => el.scrollIntoView({ block: 'start', behavior: 'smooth' })}
                    smooth to='#news'>{t("HEADER.NEWS")}</HashLink>
            ),
        } : null,

        {
            key: '5',
            label: (
                <span className={s.dropSpan} onClick={() => {
                }}>
                    {t('HEADER.DONATE')}
                </span>
            ),
        },


        {
            key: '6',
            type: 'group',
            label: t('HEADER.LANGUAGE'),
            children: [
                {
                    key: '6-1',
                    label: (
                        <span className={s.dropSpan} onClick={() => {
                            i18n.changeLanguage('hy')
                        }}>
                            Հայերեն <span className="fi fi-am"></span>
                        </span>
                    ),
                },
                {
                    key: '6-2',
                    label: (
                        <span className={s.dropSpan} onClick={() => {
                            i18n.changeLanguage('en')
                        }}>
                            English <span className="fi fi-gb"></span>
                        </span>
                    ),
                },
            ],
        },

    ]


    const join = [

        {
            key: '1',
            label: (
                <span className={s.dropSpan} onClick={() => {
                    onOpen(RegisterType.VOLUNTEER)
                }}>
                    {t('HEADER.VOLUNTEER')}
                </span>
            ),
        },
        showStudents ? {
            key: '2',
            label: (
                <span className={s.dropSpan} onClick={() => {
                    onOpen(RegisterType.STUDENT)
                }}>
                    {t('HEADER.STUDENT')}
                </span>
            ),
        } : null

    ];
    const about = [
        {
            key: '1',
            label: (
                <HashLink scroll={(el) => el.scrollIntoView({ block: 'center', behavior: 'smooth' })}
                    smooth to='#how-we-start'>{t('HEADER.HOW-WE-START')}</HashLink>
            ),
        },
        showStory ? {
            key: '2',
            label: (
                <HashLink scroll={(el) => el.scrollIntoView({ block: 'center', behavior: 'smooth' })}
                    smooth to='#success-story'>{t('HEADER.SUCCESS-STORY')}</HashLink>

            ),
        } : null,
        {
            key: '3',
            label: (
                <HashLink scroll={(el) => el.scrollIntoView({ block: 'start', behavior: 'smooth' })}
                    smooth to='#our-impact'>{t('HEADER.OUR-IMPACT')}</HashLink>

            ),
        },
    ]
    const langs = [
        {
            key: '1',
            label: (
                <span className={s.dropSpan} onClick={() => {
                    i18n.changeLanguage('hy')
                }}>
                    Հայերեն <span className="fi fi-am"></span>
                </span>
            ),
        },
        {
            key: '2',
            label: (
                <span className={s.dropSpan} onClick={() => {
                    i18n.changeLanguage('en')
                }}>
                    English <span className="fi fi-gb"></span>
                </span>
            ),
        },
    ]

    return (
        // <div className={s.headerContainer}>
        <div className={s.header}>
            <div className={s.logoDiv}>
                <img src={logo} onClick={() => {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    })
                }} />
            </div>

            <div className={s.navBar}>

                <span onClick={() => {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    })

                }}>{t("HEADER.HOME")}</span>

                <Dropdown
                    trigger={['click']}
                    className={s.dropDown}
                    menu={{
                        items: about,
                    }}>
                    <span>{t("HEADER.ABOUT-US")}</span>
                </Dropdown>
                <HashLink
                    className={s.link}
                    scroll={(el) => el.scrollIntoView({ block: 'start', behavior: 'smooth' })}
                    smooth to='#our-projects'>{t("HEADER.OUR-PROJECTS")}</HashLink>

                {
                    showNews ?
                        <HashLink
                            className={s.link}
                            scroll={(el) => el.scrollIntoView({ block: 'start', behavior: 'smooth' })}
                            smooth to='#news'>{t("HEADER.NEWS")}</HashLink>
                        : null
                }


                <HashLink
                    className={s.link}
                    scroll={(el) => el.scrollIntoView({ block: 'start', behavior: 'smooth' })}
                    smooth to='#contact'>{t("HEADER.CONTACT-US")}</HashLink>

                <Dropdown
                    className={s.dropDown}
                    trigger={['click']}
                    menu={{
                        items: join,
                    }}>
                    <span>{t("HEADER.JOIN-US")}</span>
                </Dropdown>


                <span>{t("HEADER.DONATE")}</span>

            </div>
            <div className={s.moreDiv}>

                <div className={s.langDiv}>
                    <img src={langicon} alt="" className={s.langicon} />
                </div>
                <Dropdown
                    trigger={['click']}
                    className={s.dropDown}
                    menu={{
                        items: langs,
                        selectable: true,
                    }}>
                    <span className={s.lang}>{lang === 'hy' ? 'Հայ' : 'Eng'}</span>
                </Dropdown>
            </div>



            <div className={s.mobileDropDown}>
                <Dropdown
                    trigger={['click']}
                    className={s.dropDown}
                    menu={{ items: mobileMenu }}
                >
                    <MenuOutlined />
                </Dropdown>
            </div>

        </div>
        // </div>
    )
}

