import { IStudentData, createStudent, defaultState, studentState } from '../../slices/student.slice';
import { ICourseData, courseState } from '../../slices/course.slice';
import { studentsSchema } from '../../common/validation/schemes';
import { Button, Select, Alert, message } from 'antd';
import { useTranslation } from 'react-i18next'
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../hook';
import { useSelector } from 'react-redux';
import s from './students.module.css'
import { useFormik } from 'formik';
import { Input } from '../input'


export const StudentsForm: React.FC<any> = ({ onClose }) => {

    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const [showAlert, setShowAlert] = useState(false)
    const [messageApi, contextHolder] = message.useMessage();
    const { coursesData, coursesLoading, coursesFail } = useSelector(courseState)
    const { studentFail, studentLoading, studentSuccess } = useSelector(studentState)


    const success = () => {
        messageApi.open({
            type: 'success',
            content: t('MESSAGES.SUCCESS'),
            duration: 4,
            onClose: () => {
                onClose()
                const lng = localStorage.getItem('i18nextLng')
                localStorage.clear()
                if (lng) {
                    localStorage.setItem('i18nextLng', lng)
                }
                form.resetForm({})
            }
        });
    };

    const error = () => {
        messageApi.open({
            type: 'error',
            content: t('MESSAGES.FAILED'),
            duration: 4,
            onClose: () => onClose()

        });
    };


    const form = useFormik({
        initialValues: {
            fullname: localStorage.getItem('fullname-S') || '',
            phone: localStorage.getItem('phone-S') || '',
            email: localStorage.getItem('email-S') || '',
            telegram: localStorage.getItem('telegram-S') || '',
            facebook: localStorage.getItem('facebook-S') || '',
            region: localStorage.getItem('region-S') || '',
            classroom: localStorage.getItem('classroom-S') || '',
            courseId: localStorage.getItem('courseId-S') || '',
            other: localStorage.getItem('other-S') || ''
        },
        validationSchema: studentsSchema,
        onSubmit: (v: any) => {
            let data: any = {}
            for (let key in v) {
                if (v[key]) {
                    data[key] = v[key]
                }
            }
            dispatch(createStudent(data))
        }

    })

    useEffect(() => {
        if (studentFail || coursesFail) {
            error()
            dispatch(defaultState())
        }
        if (studentSuccess) {
            success()
            dispatch(defaultState())
        }
    }, [studentSuccess, studentFail, coursesFail])


    useEffect(() => {
        for (const key in form.values) {
            let value = form.values[key as keyof IStudentData]
            localStorage.setItem(key + '-S', value)
        }
    }, [form.values])


    return <form className={s.formContainer} onSubmit={form.handleSubmit}>
        {contextHolder}
        <div className={s.inputsBox}>
            <Input
                errMess={(form.errors.fullname && form.touched.fullname) ? form.errors.fullname : null}
                id='fullname' name='fullname' value={form.values.fullname?.trimStart()} onChange={form.handleChange}
                label={t('STUDENT.FULLNAME')} />
            <Input
                errMess={(form.errors.phone && form.touched.phone) ? form.errors.phone : null}
                id='phone' name='phone' value={form.values.phone}
                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                    form.setFieldValue('phone', ev.target.value.startsWith('+') ? ev.target.value : `+${ev.target.value}`)
                }}
                label={t('STUDENT.PHONE')} />
            <Input
                errMess={(form.errors.email && form.touched.email) ? form.errors.email : null}
                id='email' name='email' value={form.values.email?.trimStart()} onChange={form.handleChange}
                label={t('STUDENT.EMAIL')} />
            <Input
                errMess={(form.errors.telegram && form.touched.telegram) ? form.errors.telegram : null}
                id='telegram' name='telegram' value={form.values.telegram?.trimStart()} onChange={form.handleChange}
                label={t('STUDENT.TELEGRAM')} />
            <Input
                errMess={(form.errors.facebook && form.touched.facebook) ? form.errors.facebook : null}
                id='facebook' name='facebook' value={form.values.facebook?.trimStart()} onChange={form.handleChange}
                label={t('STUDENT.FACEBOOK')} />
            <Input
                errMess={(form.errors.region && form.touched.region) ? form.errors.region : null}
                id='region' name='region' value={form.values.region?.trimStart()} onChange={form.handleChange}
                label={t('STUDENT.REGION')} />
            <Input
                errMess={(form.errors.classroom && form.touched.classroom) ? form.errors.classroom : null}
                id='classroom' name='classroom' value={form.values.classroom?.trimStart()} onChange={form.handleChange}
                label={t('STUDENT.CLASS')} />

            <>
                <span className={s.label}>
                    {t('STUDENT.COURSE')}
                </span>
                {(form.errors.courseId && form.touched.courseId) ?
                    <p className={s.errMess}><FadeIn>{form.errors.courseId}</FadeIn></p> : null}
                <Select
                    loading={coursesLoading}
                    className={s.select}
                    onSelect={(v) => form.setFieldValue('courseId', v)}
                    defaultValue={t('FORM.COMMON.CHOOSE')}
                    options={coursesData.map((course: ICourseData) => {
                        if (i18n.language.includes('hy')) {
                            return { value: course.id, label: course.armenian };
                        } else if (i18n.language.includes('en')) {
                            return { value: course.id, label: course.english };
                        }
                    })
                    }
                />
            </>
            <Input
                errMess={(form.errors.other && form.touched.other) ? form.errors.other : null}
                id='other' name='other' value={form.values.other?.trimStart()} onChange={form.handleChange}
                label={t('FORM.COMMON.OTHER')} />

            <div className={s.formSubmit}>
                {
                    (Object.keys(form.errors).length && showAlert) ?
                        <Alert className={s.errMessAlert}
                            type='error' message={t('VALIDATION.ERRORS.FAILED')} /> : null
                }
                <Button
                    type='primary' htmlType='submit'
                    className={s.joinBtn}
                    loading={studentLoading}
                    onClick={() => setShowAlert(true)}
                >{t('FORM.COMMON.JOIN')}</Button>
            </div>
        </div>
    </form>
}