import { AboutUs, Footer, Header, News, OurImpact, OurWork, StudentsForm, SuccessStory, VolunteersForm } from '../../components'
import { getCurrentProject, getFutureProject, getImplementedProject, projectsState } from '../../slices/project.slice';
import { CurrentProjects, FutureProjects, ImplementedProjects } from '../../components/mobile/projects';
import { Language, Projects, RegisterType } from '../../common/enums';
import { getStories, storyState } from '../../slices/success-story.slice';
import { contactState, getContacts } from '../../slices/contact.slice';
import { courseState, getCourses } from '../../slices/course.slice';
import { getImpacts, impactState } from '../../slices/impact.slice';
import { aboutState, getAbout } from '../../slices/about.slice';
import { getNews, newsState } from '../../slices/news.slice';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../hook';
import { useSelector } from 'react-redux';
import FadeIn from 'react-fade-in';
import s from './main.module.css'
import { Drawer } from 'antd'

export const Main = () => {

    let lngForRequest;
    let mobile = window.innerWidth < 680;
    const dispatch = useAppDispatch()
    const [open, setOpen] = useState('');
    const { t, i18n } = useTranslation();
    const { newsData } = useSelector(newsState)
    const { coursesData } = useSelector(courseState)
    const { aboutText, aboutLoading } = useSelector(aboutState)
    const { storyList, storyLoading } = useSelector(storyState)
    const { impactLoading, impactData } = useSelector(impactState)
    const { contactData, contactLoading } = useSelector(contactState)

    const {
        futureLoading,
        currentLoading,
        futureProjects,
        currentProjects,
        implementedLoading,
        implementedProjects,
    } = useSelector(projectsState)

    const onClose = () => {
        setOpen('');
    };

    const onOpen = (type: RegisterType) => {
        setOpen(type)
    }

    useEffect(() => {
        lngForRequest = i18n.language.includes('hy') ?
            Language.ARMENIAN :
            Language.ENGLISH
        dispatch(getAbout())
        dispatch(getImpacts())
        dispatch(getCourses())
        dispatch(getContacts())
        dispatch(getNews(lngForRequest))
        dispatch(getStories(lngForRequest))
        dispatch(getFutureProject(lngForRequest))
        dispatch(getCurrentProject(lngForRequest))
        dispatch(getImplementedProject(lngForRequest))
    }, [i18n.language])

    return (
        <>
            <Header
                showNews={newsData.length}
                showStudents={coursesData.length}
                onOpen={onOpen}
                showStory={storyList.length}

            />
            <FadeIn>
                <div>
                    <div className={s.container}>
                        <AboutUs
                            lng={i18n.language}
                            aboutLoading={aboutLoading}
                            aboutText={aboutText} />
                        {storyList.length ? <SuccessStory
                            storyLoading={storyLoading}
                            storyList={storyList}
                        /> : null}
                        <OurImpact
                            impactData={impactData}
                            impactLoading={impactLoading}
                        />
                        <OurWork
                            currentLoading={currentLoading}
                            currentProject={currentProjects}
                            implementedLoading={implementedLoading}
                            implementedProject={implementedProjects}
                            futureLoading={futureLoading}
                            futureProject={futureProjects}
                        />

                        {newsData.length ? <News /> : null}
                    </div>
                </div>
                <Footer
                    contactLoading={contactLoading}
                    contactData={contactData}
                />
                <Drawer width={mobile ? '100%' : '70%'} title={<p className={s.drawerTitle}>{t(`${open.toUpperCase()}.TITLE`)}</p>}
                    placement="right" open={Boolean(open)} onClose={onClose}>
                    {open === RegisterType.STUDENT && <StudentsForm onClose={onClose} />}
                    {open === RegisterType.VOLUNTEER && <VolunteersForm onClose={onClose} />}
                    {open === Projects.FUTURE && <FutureProjects data={futureProjects} loading={futureLoading} />}
                    {open === Projects.CURRENT && <CurrentProjects data={currentProjects} loading={currentLoading} />}
                    {open === Projects.IMPLEMENTED && <ImplementedProjects data={implementedProjects} loading={implementedLoading} />}
                </Drawer>

            </FadeIn>
        </>
    )
}