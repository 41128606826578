import { Skeleton } from 'antd'
import s from './about.module.css'
import { useTranslation } from 'react-i18next'
import { I18Language } from '../../common/enums'
import pic1 from '../../assets/Images/about/1pic.png'
import pic2 from '../../assets/Images/about/2pic.png'
import pic3 from '../../assets/Images/about/3pic.png'
import pic4 from '../../assets/Images/about/4pic.png'
import people from '../../assets/Images/about/aboutPeople.png'

interface IProps {
    aboutText: string
    aboutLoading: boolean,
    lng: string
}

export const AboutUs: React.FC<IProps> = ({ aboutText, aboutLoading, lng }) => {

    const { t } = useTranslation();
    const { am, en } = JSON.parse((typeof aboutText === 'string' && aboutText.length) ? aboutText : '{"am":"", "en": ""}')

    return (
        <div className={s.about} id='home'>
            <div className={s.aboutFirst}>
                <div className={s.donateDiv}>
                    <p className={s.aboutTitle}>{t('ABOUT.TITLE')}</p>
                    <div className={s.aboutSubBox}>
                        <p className={s.subTitle}>{t('ABOUT.SUBTITLE')}</p>

                    </div>

                </div>
                <div className={s.imgBox}>
                    <div className={s.imgDiv}>
                        <img className={s.aboutPeople} src={people} alt="" />
                    </div>
                </div>
            </div>

            <div className={s.aboutFirst} id='how-we-start'>
                {
                    aboutLoading ? <Skeleton
                        style={{ width: window.innerWidth > 680 ? '50%' : '100%' }}
                        active paragraph={{ rows: 17 }} />
                        :
                        <div className={s.aboutTextBox}
                            dangerouslySetInnerHTML={{ __html: lng === I18Language.ARMENIAN ? am : en }}>
                        </div>
                }

                <div className={s.aboutSecondBox}>
                    <div className={s.imgsBox}>
                        <div className={s.imgsDiv1}>
                            <div className={s.about2ImgDiv}>
                                <img className={s.about2Img} src={pic1} alt="" />
                            </div>
                            <div className={s.about2ImgDiv}>
                                <img className={s.about2Img} src={pic3} alt="" />
                            </div>
                        </div>
                        <div className={s.imgsDiv2}>
                            <div className={s.about2ImgDiv}>
                                <img className={s.about2Img} src={pic2} alt="" />
                            </div>
                            <div className={s.about2ImgDiv}>
                                <img className={s.about2Img} src={pic4} alt="" />
                            </div>
                        </div>
                    </div>
                </div>


            </div>


        </div>
    )
}

