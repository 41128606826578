import { useState } from 'react'
import s from './news.module.css'
import Meta from 'antd/es/card/Meta'
import { useSelector } from 'react-redux'
import FadeIn from 'react-fade-in/lib/FadeIn'
import { useTranslation } from 'react-i18next'
import { NEWS_URL } from '../../common/constants/'
import { Button, Card, Image, Modal } from 'antd'
import { newsState } from '../../slices/news.slice'



export const News: React.FC= () => {

    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const { newsData } = useSelector(newsState)
    const [loadCount, setLoadCount] = useState(1)
    const [modalInfo, setModalInfo]: any = useState({})

    const closeModal = () => {
        setOpen(false)
        setModalInfo({})
    }

    return (
        <div className={s.newsDiv} id='news'>
            <div className={s.newsContainer}>

                <div className={s.newsBox}>
                    <div>
                        <h5>{t('NEWS.TITLE')}</h5>
                    </div>
                    <div className={s.newsData}>

                        {
                            newsData?.map((item: any, i: number) => {
                                if (i > loadCount) {
                                    return
                                };
                                return <FadeIn>
                                    <div className={s.cardBox}>
                                        <Card
                                            onClick={() => {
                                                setOpen(true)
                                                setModalInfo(item)
                                            }}
                                            hoverable
                                            className={s.newsCard}
                                            cover={<img alt='news image' src={NEWS_URL + item.imgPath} />}
                                        >
                                            <Meta title={item.title} />
                                        </Card>
                                    </div>
                                </FadeIn>
                            })
                        }

                    </div>
                    <div className={s.btnDiv}>
                        {
                              loadCount >= newsData.length - 1  ? null :
                                <button
                                    onClick={() => {
                                        setLoadCount(loadCount + 2)
                                    }}
                                    className={s.button}>{t('NEWS.LOAD')}</button>
                        }
                    </div>
                </div>
            </div>
            <Modal
                onCancel={() => closeModal()}
                open={open}
                width={'70%'}
                footer={[
                    <Button onClick={() => closeModal()}>{t('NEWS.CLOSE')}</Button>
                ]}
            >
                <div className={s.modalContainer}>

                    <div className={s.imgBox}>
                        <Image
                            alt='news image'
                            style={{ borderRadius: '15px' }}
                            src={NEWS_URL + modalInfo.imgPath} />
                    </div>

                    <div className={s.contentCont}>
                        <h1>{modalInfo.title}</h1>
                        <p>{modalInfo.text}</p>
                    </div>
                </div>
            </Modal>
        </div>
    )
}