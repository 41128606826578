import CountUp from "react-countup"
// @ts-ignore
import { Spin } from 'antd';
import s from './ourimpact.module.css'
import Marquee from "react-fast-marquee"
import { useTranslation } from "react-i18next";
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;


export const MarqueeImpact: React.FC<any> = ({ impactLoading, impactData }) => {

    const { t } = useTranslation()

    return <Marquee>
        <div className={s.status}>
            {
                impactLoading ? <Spin indicator={antIcon} /> :
                    <div> <CountUp start={0} end={impactData.schools} duration={5} /> +</div>
            }
            <p>{t("IMPACT.SCHOOLS")}</p>
        </div>
        <div className={s.status}>
            {
                impactLoading ? <Spin indicator={antIcon} /> :
                    <div> <CountUp start={0} end={impactData.teachers} duration={5} /> +</div>
            }
            <p>{t("IMPACT.TEACHERS")}</p>
        </div>
        <div className={s.status}>
            {
                impactLoading ? <Spin indicator={antIcon} /> :
                    <div> <CountUp start={0} end={impactData.region} duration={5} /> +</div>
            }
            <p>{t("IMPACT.REGION")}</p>

        </div>
        <div className={s.status}>
            {
                impactLoading ? <Spin indicator={antIcon} /> :
                    <div> <CountUp start={0} end={impactData.students} duration={5} /> +</div>
            }
            <p>{t("IMPACT.STUDENTS")}</p>
        </div>
    </Marquee>
}