import { createSlice, createAsyncThunk, AnyAction } from "@reduxjs/toolkit";
import { api } from "../services/base-url";
import { Language } from "../common/enums";
import { IState } from "./interface";

interface IAbout{
    aboutText: string
    aboutLoading: boolean
}

const initialState: IAbout = {
    aboutText: '',
    aboutLoading: false,
}

export const getAbout = createAsyncThunk(
    'about/get',
    async (_, { rejectWithValue, fulfillWithValue }) => {
        try {
            const {data} = await api.get('about')
            return fulfillWithValue(data.data)
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)


const aboutSlice = createSlice({
    name: 'about',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getAbout.pending, (state: IAbout) => {
                state.aboutLoading = true
            })
            .addCase(getAbout.fulfilled, (state: IAbout, action: AnyAction) => {
                state.aboutText = action.payload.data
                state.aboutLoading = false
            })
    }
})


export const aboutState = (state: IState) => state.aboutSlice

export default aboutSlice.reducer;